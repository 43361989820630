import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { profileRespSelector } from "store/reducers/profile";

const UserDetail = () => {
  const profile = useAppSelector(profileRespSelector);

  const [isEditing, setIsEditing] = useState(false);

  const [userName, setUserName] = useState(profile?.userName || "");

  const handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };
  const dispatch = useAppDispatch();

  const handleSave = () => {
    // dispatch(changeUserInfo({ userName: userName }));
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing((prevState) => !prevState);
  };

  return (
    <div className="profile_user_detail_container">
      <img
        className="profile_logo"
        src={
          profile?.profilePhoto ||
          "https://veehivestage-images.s3.us-east-2.amazonaws.com/profileImage/defaultAvatar.png"
        }
        alt="profile"
      />
      <div
        onClick={handleEditClick}
        className="title_and_limit account_padding pointer"
      >
        <h4>Account</h4>
      </div>
      <div className="profile_menu_content_wrapper">
        <p>@{profile?.userName}</p>
        <p>{profile?.email}</p>
        <p>{profile?.phoneNo}</p>
      </div>
    </div>
  );
};

export default UserDetail;
