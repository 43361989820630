import { UserHivesModel } from "api/models/profile";
import {
  HoneyCombSVGY,
  HoneyCombSVG2,
  HoneyCombSVG3,
  HoneyCombSVG4,
  HoneyCombSVG5,
} from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useRef } from "react";
import { Honeycomb, Hexagon } from "react-honeycomb";
import { getUserHives } from "store/async-actions/profile";
import {
  honeyCombRowsSelector,
  userHivesSelector,
} from "store/reducers/profile";

const HoneyComb = () => {
  const dispatch = useAppDispatch();

  const rows = useAppSelector(honeyCombRowsSelector);

  const hivesList = useAppSelector(userHivesSelector);

  const rowStyles: React.CSSProperties[] = [
    { justifyContent: "center" },
    { justifyContent: "space-between" },
    { justifyContent: "space-around" },
    { justifyContent: "space-between" },
    { justifyContent: "center" },
  ];

  const handleHiveClick = (hive: UserHivesModel) => {
    // console.log(hive);

    window.open(
      `https://${
        hive.communitySubDomain
      }.vhive.org/?login=${localStorage.getItem("@jwtToken")}`,
      "_self"
    );
  };

  const SVGs = [
    "https://veehivestage-images.s3.us-east-2.amazonaws.com/hivesvg/yellowSVG.png",
    "https://veehivestage-images.s3.us-east-2.amazonaws.com/hivesvg/turqSvg.png",
    "https://veehivestage-images.s3.us-east-2.amazonaws.com/hivesvg/redSvg.png",
    "https://veehivestage-images.s3.us-east-2.amazonaws.com/hivesvg/blueSvg.png",
    "https://veehivestage-images.s3.us-east-2.amazonaws.com/hivesvg/purpleSvg.png",
  ];

  return (
    <div className="App">
      {hivesList && hivesList.length > 1 && (
        <div className="honeycomb-layout">
          {" "}
          {rows.map((row, index) => (
            <div className="row" key={index} style={rowStyles[index]}>
              {" "}
              {Array.from({ length: row }).map((_, i) => {
                const hiveIndex = index * 2 + i;
                const SVGComponent = SVGs[hiveIndex % 5];
                if (hiveIndex >= hivesList.length) {
                  return (
                    <div className="hex_item_svg">
                      {SVGs[hiveIndex % SVGs.length]}
                    </div>
                  ); // Handle cases where the hivesList doesn't have enough elements for the current index
                }
                return (
                  <div
                    onClick={() => handleHiveClick(hivesList[hiveIndex])}
                    className="hex_item_wrapper"
                    key={hiveIndex}
                  >
                    <h3 className="hive_hexagon_content hex_item_content">
                      {hivesList[hiveIndex].organizationName}
                    </h3>
                    <div className="hex_item_svg">
                      <img
                        src={SVGs[hiveIndex % 5]}
                        alt="svg"
                        className="hive_hexagon_imgs"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HoneyComb;
