import { useAppDispatch } from "hooks/redux";
import { useQuery } from "hooks/useQuery";
import React, { useEffect } from "react";
import BaseRoute from "routes/BaseRoute";
import { getOtherHives, getProfile } from "store/async-actions/profile";

const App = () => {
  const LOGIN_KEY = "token";

  const dispatch = useAppDispatch();

  const searchParams = useQuery();

  const token = searchParams.get(LOGIN_KEY)!;

  useEffect(() => {
    dispatch(getOtherHives(null));
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("@jwtToken", token);
    }
    dispatch(getProfile(null));
  }, [dispatch, token]);

  return (
    <div className="app_container">
      <BaseRoute />
    </div>
  );
};

export default App;
