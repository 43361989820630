import ProfileMenu from "components/ProfileMenu/ProfileMenu";
import React from "react";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <div className="main_container">
      <div className="profile_menu_container">
        <ProfileMenu />
      </div>
      <Outlet />
    </div>
  );
};

export default React.memo(MainLayout);
