import { CircularProgress } from "@mui/material";
import Bubbles from "components/Bubbles/Bubbles";
import HiveTabs from "components/HiveTabs/HiveTabs";
import HoneyComb from "components/HoneyComb/HoneyComb";
import { useAppSelector } from "hooks/redux";
import React from "react";
import { profileLoadingSelector } from "store/reducers/profile";

const HomePage = () => {
  const isLoading = useAppSelector(profileLoadingSelector);

  return (
    <div className="home_container">
      {/* <div className="hive_tabs_container">
        <HiveTabs />
      </div> */}
      {isLoading ? (
        <div className="loader_padding">
          <CircularProgress size={50} color="secondary" />
        </div>
      ) : (
        <HoneyComb />
        // <Bubbles />
      )}
    </div>
  );
};

export default HomePage;
