import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (body: null, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.profile.getProfile();
      // dispatch(getGeoInfo(null));
      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getUserHives = createAsyncThunk(
  "profile/getUserHives",
  async (body: null, { rejectWithValue }) => {
    try {
      const { data } = await api.profile.getUserHives();
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getOtherHives = createAsyncThunk(
  "profile/getOtherHives",
  async (body: null, { rejectWithValue }) => {
    try {
      const { data } = await api.profile.getOtherHives();
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
