import BaseApi from "api/base";
import { ProfileModel } from "api/models/profile";
import { AxiosInstance, AxiosPromise } from "axios";

import { BaseResponse } from "../models/base";

export class ProfileApi extends BaseApi {
  getProfile(): AxiosPromise<BaseResponse<ProfileModel>> {
    return this.request({
      url: `/user/Profile/get/userName`,
      method: "POST",
    });
  }

  getUserHives(): AxiosPromise<BaseResponse<ProfileModel>> {
    return this.request({
      url: `/webApp/hive/list`,
      method: "POST",
    });
  }

  getOtherHives(): AxiosPromise<BaseResponse<ProfileModel>> {
    return this.request({
      url: `${
        localStorage.getItem("isLoggedIn") === "yes"
          ? "/community/admin/others"
          : "/webApp/noAuth/hive/list"
      }`,
      method: "POST",
    });
  }
}

export default function profileApi(request: AxiosInstance) {
  return new ProfileApi({
    request,
    url: `/`,
  });
}
