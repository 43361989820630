import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import {
  getOtherHives,
  getProfile,
  getUserHives,
} from "store/async-actions/profile";
import { ProfileItem, UserHivesModel } from "api/models/profile";
import { BaseResponse } from "api/models/base";
import axios from "axios";
import { WritableDraft } from "immer/dist/internal";

const initialState: {
  profileResp: ProfileItem | undefined;
  isFetching: boolean;
  isMemberView: boolean;
  userId: number;
  userName: string;
  countryCode: string;
  countryName: string;
  profileDetails: ProfileItem | undefined;
  userHives: UserHivesModel[];
  honeyCombRows: number[];
} = {
  profileResp: undefined,
  isFetching: false,
  isMemberView: false,
  userId: 0,
  userName: "",
  countryCode: "",
  countryName: "",
  profileDetails: undefined,
  userHives: [],
  honeyCombRows: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setIsMemberView: (state, action: PayloadAction<boolean>) => {
      state.isMemberView = action.payload;
    },
  },
  extraReducers: {
    [getProfile.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getProfile.fulfilled.type]: (
      state,
      action: PayloadAction<BaseResponse<ProfileItem>>
    ) => {
      getGeoInfoJson();
      if (action.payload.responseInfo.httpCode === 200) {
        state.profileResp = action.payload.data;
        state.userId = action.payload.data.userId;
        state.userName = action.payload.data.userName;
        localStorage.setItem("isLoggedIn", "yes");
      } else {
        localStorage.removeItem("@jwtToken");
        localStorage.setItem("isLoggedIn", "no");
      }
      state.isFetching = false;
    },
    [getProfile.rejected.type]: (state) => {
      localStorage.setItem("isLoggedIn", "no");
      state.isFetching = false;
    },

    //Get user hives
    [getUserHives.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getUserHives.fulfilled.type]: (
      state,
      action: PayloadAction<UserHivesModel[]>
    ) => {
      state.userHives = action.payload;
      state.honeyCombRows = calculateRows(action.payload.length);
      state.isFetching = false;
    },
    [getUserHives.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Get other hives
    [getOtherHives.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getOtherHives.fulfilled.type]: (
      state,
      action: PayloadAction<UserHivesModel[]>
    ) => {
      state.userHives = action.payload;
      state.honeyCombRows = calculateRows(action.payload.length);
      state.isFetching = false;
    },
    [getOtherHives.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const { setIsMemberView } = profileSlice.actions;

function calculateRows(svgCount: number): number[] {
  var check = svgCount;
  var current = 1;
  var resultArray: number[] = [];

  var done: boolean = false;
  var allDone: boolean = false;

  while (!allDone) {
    while (!done) {
      if (check - current * 2 >= 0) {
        resultArray.push(current);
        check = check - current * 2;
        current += 2;
      } else {
        resultArray.push(current);
        done = true;
      }
    }

    if (done && current > 1) {
      current -= 2;
      resultArray.push(current);
    }
    if (current === 1) {
      allDone = true;
    }
  }

  return resultArray;
}

const getGeoInfoJson = () => {
  axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      let data = response.data;
      localStorage.setItem("countryCode", data.country_code);
    })
    .catch((error) => {});
};

export const launchLogin = () => {
  window.open(`https://login.vhive.org/?domain=vhive.org`, "_self");
};

export const countryCodeSelector = createTypedSelector(
  (state) => state.profile.countryCode
);

export const userHivesSelector = createTypedSelector(
  (state) => state.profile.userHives
);

export const honeyCombRowsSelector = createTypedSelector(
  (state) => state.profile.honeyCombRows
);

export const profileRespSelector = createTypedSelector(
  (state) => state.profile.profileResp
);

export const profileDetailsSelector = createTypedSelector(
  (state) => state.profile.profileDetails
);

export const profileLoadingSelector = createTypedSelector(
  (state) => state.profile.isFetching
);

export const isMemberViewSelector = createTypedSelector(
  (state) => state.profile.isMemberView
);

export const profileUserIdSelector = createTypedSelector(
  (state) => state.profile.userId
);

export const getProfileUsernameSelector = createTypedSelector(
  (state) => state.profile.userName
);

export default profileSlice.reducer;
