import MainLayout from "components/MainLayout";
import HomePage from "pages/HomePage";
import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

export enum Routes {
  BASE = "/",
  HOME = "/home",
}

export enum AbsoluteRoutes {
  GO_BACK = "..",
  HOME = "home",
}

const router = createBrowserRouter([
  {
    path: Routes.BASE,
    element: <MainLayout />,
    errorElement: <MainLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.HOME} replace={true} />,
      },
      {
        path: Routes.HOME,
        element: <HomePage />,
      },
    ],
  },
]);

const BaseRoute = () => {
  return <RouterProvider router={router} />;
};

export default BaseRoute;
