import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { launchLogin, profileRespSelector } from "store/reducers/profile";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import LineBreak from "components/LineBreak/LineBreak";
import UserDetail from "components/UserDetail";
import { getOtherHives, getUserHives } from "store/async-actions/profile";

const ProfileMenu = () => {
  const profile = useAppSelector(profileRespSelector);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("@jwtToken");
    localStorage.setItem("isLoggedIn", "no");
    navigate("/home");
  };

  const handleChange = (newValue: number) => {
    if (newValue === 0) {
      if (localStorage.getItem("isLoggedIn") === "yes") {
        dispatch(getUserHives(null));
      } else {
        launchLogin();
      }
    } else {
      dispatch(getOtherHives(null));
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <Tooltip title="Profile">
          <img
            src={
              profile?.profilePhoto ||
              "https://veehivestage-images.s3.us-east-2.amazonaws.com/profileImage/defaultAvatar.png"
            }
            className="hive_logo"
            onClick={handleClick}
          />
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "250px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {localStorage.getItem("isLoggedIn") === "yes" ? (
          <>
            <UserDetail />
            <LineBreak />
            <Divider />
            <LineBreak />
          </>
        ) : (
          <div></div>
        )}
        <div className="profile_hive_selector">
          <p onClick={() => handleChange(0)} className="pointer selector">
            My Hives
          </p>
          <p onClick={() => handleChange(1)} className="pointer selector">
            Public Hives
          </p>
        </div>
        <Divider />
        <MenuItem onClick={handleClose}>
          <div className="flex items-center">
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <p onClick={handleLogout} className="text_xs font-ibm">
              {localStorage.getItem("isLoggedIn") === "yes"
                ? "Logout"
                : "Login"}
            </p>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
